'use client'
import {useEffect, useState} from "react";
import {ErrorScreenMobile} from "../ErrorScreenMobile/ErrorScreenMobile";
import {ErrorScreen} from "../ErrorScreen/ErrorScreen";

export const AppCrash = () => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0)
  const wrapper = typeof document !== 'undefined' && document.querySelector('.wrapper')

  const updateCurrentWidth = () => {
    const currentWidth =
      wrapper instanceof HTMLElement
        ? wrapper.offsetWidth
        : typeof window !== 'undefined' ? window.innerWidth : 0
    setWidth(currentWidth)
  }


  useEffect(() => {
    const currentWidth =
      wrapper instanceof HTMLElement
        ? wrapper.offsetWidth
        : typeof window !== 'undefined' ? window.innerWidth : 0
    setWidth(currentWidth)
  }, [wrapper])

  typeof window !== 'undefined' && window.addEventListener('resize', updateCurrentWidth)

  return (
    <div className={'wrapper'}>
      {width > 1440 ?
        <ErrorScreen
          type={'crash'}
          title={'Что-то пошло не так'}
          description={'Ошибка приложения'}
          buttonText={'Вернуться на главную'}
          handler={() => {
            window.location.replace(`/`)
          }}
        />
        :
        <ErrorScreenMobile
          type={'crash'}
          title={'Что-то пошло не так'}
          description={'Ошибка приложения'}
          buttonText={'Вернуться на главную'}
          handler={() => {
            window.location.replace(`/`)
          }}
        />
      }
    </div>
  )
}