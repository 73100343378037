import {CSSProperties} from "react";

export const LogoPale = ({style}: {style: CSSProperties}) => {
  return (
    <svg width="70" height="77" viewBox="0 0 70 77" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g opacity="0.05">
        <path d="M59.8742 65.9649C61.4787 64.3712 62.9293 62.6246 64.1986 60.7471C59.9622 59.1971 56.1598 57.0303 53.0333 53.7937C49.4398 50.0769 47.0111 45.5032 45.8517 40.6074L41.401 48.4122C43.0384 52.0745 45.3132 55.4748 48.176 58.4384C49.753 60.0703 51.4783 61.5385 53.341 62.8211C42.7857 71.6848 27.2137 71.6848 16.6583 62.8211C18.5155 61.5385 20.2464 60.0649 21.8234 58.4384C24.5927 55.573 26.8126 52.2983 28.4335 48.767L24.0377 41.0495C22.8399 45.7815 20.4497 50.1915 16.966 53.7992C13.8395 57.0357 10.0427 59.1971 5.80078 60.7526C7.07006 62.6301 8.52066 64.3766 10.1251 65.9703C23.8399 79.5933 46.1539 79.5933 59.8687 65.9703" fill="#006CEC"/>
        <path d="M13.5359 22.3925C9.24351 27.3786 6.64107 33.9079 6.64107 41.0521C6.64107 45.4013 7.60214 49.5198 9.3245 53.2045C8.83857 53.512 8.34184 53.803 7.83971 54.0831C6.39271 54.8793 4.88092 55.5438 3.31514 56.0764C1.18783 51.5241 0 46.4281 0 41.0521C0 31.3654 3.86046 22.5902 10.1074 16.2422L13.5359 22.3925Z" fill="#006CEC"/>
        <path d="M14.3086 13.1516C16.1407 11.7988 18.0768 10.6479 20.0839 9.69872L34.9707 35.1674L49.491 10.1897C51.6568 6.464 57.0766 2.74924 63.0379 0L34.9269 49.5028L14.3086 13.1516Z" fill="#006CEC"/>
        <path d="M41.4303 13.461C37.0756 12.4755 32.5294 12.4968 28.1856 13.5356L27.7316 12.7579C26.7359 11.0533 25.7402 9.35404 24.75 7.66013C31.293 5.73183 38.3011 5.69454 44.8605 7.56424L41.4303 13.461Z" fill="#006CEC"/>
        <path d="M60.0482 16.4715C66.1941 22.783 70.0008 31.5076 70.0008 41.1386C70.0008 46.4837 68.8311 51.5503 66.7364 56.0764C65.1946 55.5468 63.706 54.8808 62.2811 54.0946C61.7867 53.8216 61.2976 53.5267 60.8191 53.221C62.5151 49.5575 63.4614 45.4627 63.4614 41.1386C63.4614 33.9154 60.8138 27.3309 56.4648 22.3407L59.8302 16.2422C59.9046 16.3186 59.9791 16.3896 60.0535 16.466" fill="#006CEC"/>
      </g>
    </svg>
  )
}