import { Danger } from '@/icons/Danger'
import classNames from "classnames";
import styles from "./styles.module.scss";
import {LogoPale} from "@/images/LogoPale";

interface IErrorScreen {
  type: '404' | 'crash' | 'denied'
  title: string
  description?: string
  buttonText: string
  handler: () => void
}

export const ErrorScreenMobile = (
  {
    type,
    description,
    title,
    buttonText,
    handler,
  }: IErrorScreen) => {
  const bgColors = {
    '404': 'gray80',
    denied: 'light',
    crash: 'accent20',
  }
  return (
    <div
      className={classNames(
        styles.wrapper,
      )}
    >
      <div style={{ marginTop: 'auto', position: 'relative' }}>
        {type === '404' &&
          <div
            className={classNames(
              styles.styled404,
            )}>
              404
          </div>
        }
        {type === 'crash' && (
          <div style={{display: 'flex', marginBottom: '30px', justifyContent: 'center'}}>
            <Danger color={'hsla(0, 100%, 58%, 1)'} size={40} />
          </div>
        )}
        <h1 className={classNames(
          styles.title,
        )}>
          {title}
        </h1>
        <p
          className={classNames(
            styles.description,
          )}
        >
          {description}
        </p>
        {type === 'denied' &&
          <LogoPale
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%) scale(3)',
              width: 'auto'
            }}
          />
        }
      </div>
      <button
        aria-label={buttonText}
        className={classNames(
          styles.button,
        )}
        onClick={handler}
        style={{ marginTop: 'auto' }}
      >
        {buttonText}
      </button>
    </div>
  )
}
